<template>
  <span class="py-0.5 px-2 rounded text-body-7 capitalize" :class="classes">
    {{ label }}
    <button v-if="hasIcon" type="button" class="flex-shrink-0 inline-flex items-center justify-center"
            :class="imgClasses">
      <svg class="h-2 w-2" stroke="currentColor" fill="white" viewBox="0 0 8 8">
        <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7"/>
      </svg>
    </button>
    <slot/>
  </span>
</template>

<script setup>
import {computed, defineProps} from 'vue';

const props = defineProps({
    label: {
        type: String,
    },
    hasIcon: {
        type: Boolean,
        default: false
    },
    size: {
        type: String,
        validator: function (value) {
            return ['small', 'big'].indexOf(value) !== -1;
        },
    },
    type: {
        type: String,
        required: true,
    },
    isActive: {
        type: Boolean,
        default: false
    }
});

const classes = computed(() => {
    switch (props.type) {
    case 'user-registration':
        return 'bg-notification-secondary-8 text-notification-primary-8';
    case 'import':
        return 'bg-notification-secondary-4 text-notification-primary-4';
    case 'groups':
        return 'bg-notification-secondary-1 text-notification-primary-1';
    case 'emails':
        return 'bg-notification-secondary-5 text-notification-primary-5';
    case 'delivered':
    case 'open':
    case 'click':
    case 'approved':
        return 'bg-tag-secondary-9 text-tag-primary-9 hover:bg-tag-primary-9 hover:text-tag-secondary-9';
    case 'deferred':
    case 'processed':
    case 'deactivated':
        return 'bg-tag-secondary-1 text-tag-primary-1 hover:bg-tag-primary-1 hover:text-tag-secondary-1';
    case 'dropped':
    case 'bounce':
    case 'invited':
        return 'bg-tag-secondary-2 text-tag-primary-2 hover:bg-tag-primary-2 hover:text-tag-secondary-2';
    case 'pending':
        return 'bg-notification-secondary-2 text-notification-primary-2 hover:bg-notification-primary-2 hover:text-notification-secondary-2';
    default:
        return 'bg-primary-1 text-white';
    }
});

const formatType = computed(() => {
    if (props.type !== 'default') {
        return props.type.split('-').join(' ');
    }
    return props.label;
})

const imgClasses = computed(() => {
    return {
        'ml-1 h-4 w-4 rounded': props.size === 'small' && props.type === 'default',
        'ml-2 h-5 w-5': props.size === 'big'
    }
});
</script>

