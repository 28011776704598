export function useLogout() {
    async function logoutCometChat() {
        const { CometChat } = await import("@cometchat-pro/chat");
        const loggedInUser = await CometChat.getLoggedinUser();

        if (loggedInUser) {
            try {
                await CometChat.logout();
            } catch (error) {
                console.error("CometChat logout failed", error);
            }
        } else {
            console.log("No user is logged in CometChat");
        }
    }

    const handleLogout = async function (redirectToLogin = true) {
        const { usePreloader } = await import("@/composables/usePreloader");
        const { useNotifications } = await import("@/composables/useNotifications");
        const { useAuth } = await import("@/stores/AuthStore");
        const { useOnboarding } = await import("@/stores/OnboardingStore");
        const { useCookies } = await import("@vueuse/integrations/useCookies");
        const { useRouter } = await import("vue-router");
        const { storeToRefs } = await import("pinia");

        const { showPreloader, hidePreloader } = usePreloader();
        const { deleteFcmToken } = useNotifications();
        const router = useRouter();
        const cookie = useCookies(null, { doNotParse: true });

        const { token, user, member, fields, organization, notificationToken } = storeToRefs(useAuth());
        const { currentStep } = storeToRefs(useOnboarding());

        showPreloader();

        if (notificationToken.value !== null) {
            await deleteFcmToken(organization.value.slug, member.value.uid, notificationToken.value.uid)
                .then(() => {
                    notificationToken.value = null;
                }).catch(err => {
                    if (err.status === 404) {
                        console.log('notification token not found');
                    }
                });
        }

        token.value = null;
        user.value = null;
        fields.value = [];
        currentStep.value = 0;

        cookie.remove('user-uid', {
            path: '/',
            domain: window.appConfig.DOMAIN
        });
        await logoutCometChat();

        if(redirectToLogin) {
            window.location.href = '/login';
        }

        member.value = null;

        hidePreloader();
    };

    return {
        handleLogout,
        logoutCometChat,
    };
}