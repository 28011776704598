import {getSupportedRegionCodes, parsePhoneNumber} from "awesome-phonenumber";

export function phone(regionCode, strict, errorMessage) {
    let availableRegions = getSupportedRegionCodes();

    const errMsg =
        typeof errorMessage === 'string' && errorMessage
            ? errorMessage
            : availableRegions.includes(regionCode)
                ? `\${path} must be a valid phone number for ${regionCode.toLowerCase()} region`
                : '${path} must be a valid phone number.';

    if (!regionCode) {
        regionCode = 'US';
    }

    return this.test('phone', errMsg, (value) => {
        if (!value && !strict) {
            return true;
        }

        try {
            const phoneNumber = parsePhoneNumber(value, {regionCode: regionCode});

            if (!phoneNumber.possible) {
                return false;
            }

            if (strict && phoneNumber.regionCode !== regionCode.toUpperCase()) {
                return false;
            }

            return phoneNumber.valid;
        } catch {
            return false;
        }
    })
}


