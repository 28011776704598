import {initializeApp , setLogLevel} from "firebase/app";
import {getToken, getMessaging, onMessage} from "@firebase/messaging";
import {toast} from "vue3-toastify";
import {h,  ref} from "vue";
import PushNotification from "../components/toasts/PushNotification.vue";

const firebaseConfig = {
    apiKey: window.appConfig.FIREBASE_API_KEY,
    authDomain: window.appConfig.FIREBASE_AUTH_DOMAIN,
    projectId: window.appConfig.FIREBASE_PROJECT_ID,
    storageBucket: window.appConfig.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: window.appConfig.FIREBASE_MESSAGING_SENDER_ID,
    appId: window.appConfig.FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);

setLogLevel('silent');
const messaging = getMessaging(app);

export const notificationState = {
    allMembersWiped: ref(false),
    allMembersImported: ref(false),
    toBeImported: ref(0),
    alreadyImported: ref(0),
};

const getFirebaseTokenOrRequestPermission = () => {
    return getToken(messaging, {vapidKey: window.appConfig.FIREBASE_VAPID_KEY})
        .then((token) => {
            return Promise.resolve(token);
        })
        .catch((err) => {
            Notification.requestPermission()
                .then((permission) => {
                    if (permission === 'granted') {
                        console.log('Notification permission granted.');
                    } else {
                        console.log('Notification permission denied.');
                    }
                });

            return Promise.reject(err);
        });
}

let alreadyImportedCounter = 0;
onMessage(messaging, (payload) => {
    if(Object.prototype.hasOwnProperty.call(payload.data, 'already-wiped') && Object.prototype.hasOwnProperty.call(payload.data, 'to-be-wiped')) {
        const alreadyWiped = payload.data['already-wiped'];
        const toBeWiped = payload.data['to-be-wiped'];

        if (alreadyWiped === toBeWiped) {
            notificationState.allMembersWiped.value = true;
        }
    }

    if(Object.prototype.hasOwnProperty.call(payload.data, 'already-imported') && Object.prototype.hasOwnProperty.call(payload.data, 'to-be-imported')) {
        if(!notificationState.allMembersImported.value) {
            alreadyImportedCounter = notificationState.alreadyImported.value;

            let options = {
                autoCLose: Number(payload.data.duration) ?? false,
            };

            toast(h(PushNotification, {
                title: payload.notification.title,
                body: payload.notification.body,
                go_to: payload.data.go_to ?? null,
                timestamp: payload.data.timestamp ?? null,
            }), options);
        }

        notificationState.alreadyImported.value = Number(payload.data['already-imported']);
        notificationState.toBeImported.value = Number(payload.data['to-be-imported']);

        if (notificationState.alreadyImported.value ===  notificationState.toBeImported.value) {
            notificationState.allMembersImported.value = true;
        }
    }
});

export {getFirebaseTokenOrRequestPermission, messaging};
